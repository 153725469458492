import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import MiddleBox from "../../components/middleBox"
import Splash from "../../components/splash"
import LongBlocks from "../../components/longBlocks"

import { CASE_STUDIES } from "../../config"

const CaseStudiesPage = ({ images }) => (
  <Layout>
    <SEO title="Home" />
    <Splash title="Test Page" subtitle="">
      <Img style={{ minHeight: "150px" }} fluid={images[0]} />
    </Splash>
    <MiddleBox>
      To learn more about the history and details of select assets that Essex
      Capital has acquired, developed, and redeveloped, please click on the
      individual case study profiles below.
    </MiddleBox>
    <LongBlocks blocks={renderBlocks()} />
  </Layout>
)

const renderBlocks = () => {
  return Object.keys(CASE_STUDIES).map((k, i) => {
    const case_ = CASE_STUDIES[k]
    const title = case_.title
    const text = case_.summary.about
    const href = case_.href
    const capabilities = case_.summary.capabilities
    return {
      title,
      text,
      href,
      capabilities,
      case: k,
    }
  })
}

export default props => {
  const data = useStaticQuery(graphql`
    query {
      splashImage: file(relativePath: { eq: "banners/case-studies.png" }) {
        childImageSharp {
          fluid(maxWidth: 1180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const splashImage = data.splashImage.childImageSharp.fluid

  const images = [splashImage, ,]
  return <CaseStudiesPage {...props} images={images} />
}
